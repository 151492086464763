$(document).ready(function() {
  var splits = {};

  var updateTables = function() {
    $("table.responsive").each(function(i, element) {
      var $element = $(element);
      var guid = Ember.guidFor(element);
      if ($element.width() > $element.parent().width()) {
        if (!splits[guid]) {
          unsplitTable($element);
          splitTable($element);
          splits[guid] = true;
        }
      } else {
        unsplitTable($element);
        splits[guid] = false;
      }
    });
  };

  updateTables = _.throttle(updateTables, 100);

  $(window).on("redraw", updateTables);
  $(window).on("resize", updateTables);

  function splitTable(original)
  {
    if (original.parents('.table-wrapper').length != 0) { return }
    original.wrap("<div class='table-wrapper' />");

    var copy = original.clone();
    copy.find("td:not(:first-child), th:not(:first-child)").css("display", "none");
    copy.removeClass("responsive");

    original.closest(".table-wrapper").append(copy);
    copy.wrap("<div class='pinned' />");
    original.wrap("<div class='scrollable' />");

    setCellHeights(original, copy);
  }

  function unsplitTable(original) {
    if (original.parents('.table-wrapper').length == 0) { return }
    original.closest(".table-wrapper").find(".pinned").remove();
    original.unwrap();
    original.unwrap();
  }

  function setCellHeights(original, copy) {
    // WTF, bring this back if you find a reason.
    // var tr = original.find('tr'),
    //     tr_copy = copy.find('tr'),
    //     heights = [];

    // tr.each(function (index) {
    //   var self = $(this),
    //       tx = self.find('th, td');

    //   tx.each(function () {
    //     var height = $(this).outerHeight(true);
    //     heights[index] = heights[index] || 0;
    //     if (height > heights[index]) heights[index] = height;
    //   });

    // });

    // tr_copy.each(function (index) {
    //   $(this).height(heights[index]);
    // });
  }

  window.ZurbTables = {};
  window.ZurbTables.updateTables = updateTables;
});
